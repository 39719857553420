.Button {
  width: 10%;
}

.button {
  padding-top: 5px;
  margin-top: 10px;
  width: 60%;
  padding: 10px 0;
  font-size: 18px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.button:hover {
  background-color: black;
  font-weight: bold;
  color: #fff;
}
.button-delete {
  margin-top: 5px;
}
.button-group .btn {
  flex-grow: 1;
  margin-right: 5px;
}
