.navbar {
  background-color: #3d3d3d;
  color: #fff;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.nav-links {
  color: #fff !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  margin-left: 20px;
  transition: color 0.3s ease-in-out;
}

.nav-links.active {
  color: #ffd700 !important;
  text-decoration: none !important;
}
.navbar-brand-custom {
  display: flex;
  align-items: center;
  color: #f1c40f;
  font-size: 1.5rem;
  font-weight: bold;
}
.navbar-brand-custom img {
  margin-right: 10px;
}

.navbar-brand-custom:hover {
  color: #f1c40f;
  text-decoration: none;
}
.sticky-navbar {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}
.nav-tabs {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
  border-bottom: 2px solid black;
  background-color: transparent;
}
.nav-section {
  margin-bottom: 20px;
  background-color: transparent;
}
.nav-tabs .profile-link {
  padding: 10px;
  border: none;
}

.nav-tabs .profile-link.active {
  font-weight: bold;
  background-color: transparent !important;
}

.nav-section {
  margin: 20px 0;
}

.nav-link {
  display: block;
  padding: 10px;
  color: #000;
  text-decoration: none;
}
.nav-link.active {
  font-weight: bold !important;
}
.tag-brand {
  color: #fff;
  font-size: 25px;
  padding: 5px;
}
